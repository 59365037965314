
import { Component, Vue } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'
import { FormData } from '@/types/advertising'
import { ElForm } from 'element-ui/types/form'
import { pInteger } from '@/utils/validate'
@Component({
  name: 'AdvertisingAdd',
  components: { UploadFile }
})
export default class extends Vue {
  private formData: FormData = {
    projectId: '',
    advertiseTitle: '',
    advertiseContent: '',
    imgList: [],
    startTime: '',
    endTime: '',
    headImg: null,
    videoRecourse: null,
    orderNum: ''
  }

  private formDataRules = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: ['blur', 'change'] }
    ],
    advertiseTitle: [
      { required: true, message: '请输入广告标题', trigger: 'blur' }
    ],
    advertiseContent: [
      { required: true, message: '请输入广告内容', trigger: 'blur' }
    ],
    headImg: [
      { required: true, message: '请上传广告头图', trigger: ['blur', 'change'] }
    ],
    imgList: [
      { required: true, message: '请上传图片', trigger: ['blur', 'change'] }
    ],
    orderNum: [
      // { required: true, message: '请输入序号', trigger: 'blur' },
      { validator: pInteger, trigger: ['blur', 'change'] }
    ]
  }

  private advertiseId = ''
  private btnDisable = false
  private timers: string[] = []
  private showWarn = false

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.advertiseId = this.$route.params.advertiseId
    this.advertiseId && this.getDetail()
  }

  // 获取详情
  getDetail () {
    this.$axios
      .get(this.$apis.advertising.selectAdvertiseByAdvertiseId, {
        advertiseId: this.advertiseId
      })
      .then((res) => {
        console.log('res: ', res)
        if (res) {
          this.timers = [res.startTime, res.endTime]
          this.formData = res
        }
      })
  }

  // 广告图上传成功
  imgUploadSuccess (file: FileInfo) {
    console.log('file: ', file)
    this.formData.headImg = file
    ;(this.$refs.formData as ElForm).clearValidate('headImg')
  }

  // 图片列表上传成功
  uploadListSuccess (file: FileInfo) {
    console.log('file: ', file)
    this.formData.imgList.push(file)
    ;(this.$refs.formData as ElForm).clearValidate('imgList')
  }

  // 视频上传成功
  videoUploadSuccess (file: FileInfo) {
    this.formData.videoRecourse = file
  }

  // 删除广告图
  removeImg () {
    this.formData.headImg = null
  }

  // 删除图片列表
  removeImgList (index: number) {
    this.formData.imgList.splice(index, 1)
  }

  // 删除视频
  removeVideo () {
    this.formData.videoRecourse = null
  }

  // 展示时间
  changetimers (val: string[]) {
    if (val && val[0] && val[1]) {
      this.formData.startTime = val[0]
      this.formData.endTime = val[1]
      this.showWarn = false
    } else {
      this.formData.startTime = ''
      this.formData.endTime = ''
      this.showWarn = true
    }
  }

  // 确定新增
  confirmAdd () {
    ;(this.$refs.formData as ElForm).validate((valid) => {
      if (
        !this.timers ||
        this.timers.length <= 0 ||
        !this.timers[0] ||
        !this.timers[1]
      ) {
        this.showWarn = true
      }
      if (valid && !this.showWarn) {
        this.btnDisable = true
        const urls = this.advertiseId
          ? this.$apis.advertising.updateAdvertise
          : this.$apis.advertising.insertAdvertise
        this.$axios
          .post(urls, this.formData)
          .then(() => {
            this.$message.success('保存成功')
            this.$router.push({ path: '/advertisingList' })
          })
          .finally(() => {
            this.btnDisable = false
          })
      }
    })
  }
}
